<template>
  <div class="main">
    <div class="tit niche_title">
      <span>商机响应</span>
    </div>
    <div class="niche_content_wrap" v-if="list.length > 0">
      <div class="niche_content" v-loading="loading">
      <div v-for="item in list" :key="item.id" class="niche_card_item">
        <div class="niche_card_status">
          <span class="niche_card_status_stage">{{item.project_name}}</span>
          <span class="niche_card_status_step" :style="tagStyle[item.project_status]">{{tagStyle[item.project_status].text || ''}}</span>
        </div>
        <div class="niche_card_info niche_card_info1">
          <span>项目品牌 / 大概预算：</span>
          <span>{{item.brand}} / {{item.budget_range}}</span>
        </div>
        <div class="niche_card_info niche_card_info2">
          <span>项目背景：</span>
          <span>{{item.project_bg}}</span>
        </div>
        <div class="niche_card_info niche_card_info3">
          <span>报名截止时间：</span>
          <span>{{item.deadline}}</span>
        </div>
        <div class="niche_card_del">
          <el-button v-show="!+item.is_enrolled && item.project_status == 2" type="primary" @click="signUp(item.id)">立即报名</el-button>
          <el-button type="primary" plain @click="goToDetail(item.id)">查看详情</el-button>
        </div>
      </div>
    </div>
    <div class="niche_pagination">
      <el-pagination  @current-change="changePage" hide-on-single-page background layout="prev, pager, next" :total="total" />
    </div>
    </div>
    <el-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
//project_status标签状态
// 1：待处理；2：进行中；98：已报名；99：已过期
export default {
  data() {
    return {
      page:1,
      list:[],
      total:0,
      loading:true,
      tagStyle:{
        2:{background:'rgba(1,187,134,0.08)',color:'#01BB86',text:'进行中'},
        98:{background:'rgba(245,99,11,0.08)',color:'#F5630B',text:'已报名'},
        99:{background:'rgba(106,112,132,0.08)',color:'#6A7084',text:'已结束'}
      }
    };
  },
  created() {
    this.getList()
  },
  methods:{
    goToDetail(id){
      window.open(`/person/niche/detail?id=${id}`)
    },
    signUp(id){
      this.API.demand_enrol({id,opType:1},() =>{
        ElMessage.success('报名成功！');
        this.getList()
      })
    },
    async getList(){
      this.loading = true;
      try{
        const query = {
        type:2,
        pageSize:10,
        page:this.page
      }
        this.API.demand_list(query,(res) => {
          this.list = res.data.list;
          this.total = res.data.total;
        })
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false;
      }

    },
    changePage(page){
      this.page = page
      this.getList()
    }
  }
};
</script>

<style lang="less" type="text/css" scoped="">
@import url('../../assets/css/mixin');
.main {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
}
.niche_title {
  display: flex;
  justify-content: space-between;
  .niche_btn {
    padding: 0 16px;
    height: 32px;
    background: #f5630b;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    line-height: 32px;
    margin-top: -10px;
  }
}
.niche_content {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 500px;
  .niche_card_item {
    height: 248px;
    width: 547px;
    border: 1px solid #e3e6ec;
    border-radius: 6px;
    padding: 24px 24px 16px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    .niche_card_status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .niche_card_status_stage {
        font-size: 16px;
        color: #0b0b0b;
        line-height: 22px;
        flex:1;
        .multi-text(1);
      }
      .niche_card_status_step {
        width: 48px;
        height: 20px;
        background: rgba(1, 187, 134, 0.08);
        border-radius: 2px;
        font-size: 12px;
        color: #01bb86;
        line-height: 20px;
        text-align: center;
        margin-left: 12px;
      }
    }
    .niche_card_info {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;
      > span:first-child {
        color: #2e3036;
      }
      > span:last-child {
        color: #888888;
      }
    }
    .niche_card_info1{
      .multi-text(1);
    }
    .niche_card_info2{
      .multi-text(3);
    }
    .niche_card_info3{
      .multi-text(1);
    }
    .niche_card_del {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items:flex-end
    }
  }
}
.niche_pagination{
  display: flex;
  justify-content: flex-end;
}
</style>
